import {
  ProjectImage,
  ProjectImageAuditItem,
  ProjectImageDetails,
  ProjectImagesQuery,
  ProjectImagesUserSettings,
  ProjectImageUploadSession,
  ProjectSearchImageModel,
  ProjectSearchImagesQuery,
  ProjectUploadingImages,
  QueryFilterModelNew,
  QueryModel,
  ProjectImagesZip,
  VisibilityModel,
  ProjectImageComment,
} from 'ngx-q360-lib';
import { projectImagesQuery, projectSearchImagesQuery, queryFilter } from '@core/helpers/query.helper';

export interface ProjectImageAnalyticsStateModel {
  searchedImages: ProjectSearchImageModel;
  searchedImagesLoading: boolean;
  searchedImagesError: any;
  searchedImagesQuery: ProjectSearchImagesQuery;
  currentSearchedImagesQuery: ProjectSearchImagesQuery | null;
  uploadSessions: ProjectImageUploadSession[];
  uploadSessionsCount: number;
  uploadSessionsQuery: QueryFilterModelNew;
  uploadSessionsLoading: boolean;
  uploadSessionsLoadingMore: boolean;
  uploadSessionsError: any;
  uploadingImages: ProjectUploadingImages[];
  uploadFolder: string;
  images: ProjectImage[];
  imagesLoading: boolean;
  imagesLoadingMore: boolean;
  imagesTotalCount: number;
  imagesQuery: ProjectImagesQuery;
  settings: ProjectImagesUserSettings[];
  settingsLoading: boolean;
  selectedImage: ProjectImageDetails | null;
  selectedImageLoading: boolean;
  selectedImageTags: string[];
  selectedImageTagsLoading: boolean;
  selectedImageAudit: QueryModel<ProjectImageAuditItem>;
  selectedImageAuditLoading: boolean;
  selectedImagesIds: string[];
  bulkUpdateLoading: boolean;
  imagesLink: string | null;
  imageLinkIds: string[];
  zipFiles: ProjectImagesZip[];
  zipFilesTotalCount: number;
  zipFilesQuery: QueryFilterModelNew;
  zipFilesLoading: boolean;
  zipFileLoading: boolean;
  creatingZipLoading: boolean;
  imageVisibility: VisibilityModel[];
  followersLoading: boolean;
  comments: ProjectImageComment[];
  commentsTotalCount: number;
  commentsLoading: boolean;
  lastCheckedId: string | null;
  lastProjectId: string | null;
}

export const defaultState: ProjectImageAnalyticsStateModel = {
  searchedImages: {
    unfilteredCount: 0,
    withLocationCount: 0,
    totalCount: 0,
    tags: [],
    mediaLocations: [],
    months: [],
    noLocationCount: 0,
    detectedObjects: [],
  },
  searchedImagesLoading: false,
  searchedImagesError: null,
  searchedImagesQuery: { ...projectSearchImagesQuery },
  currentSearchedImagesQuery: null,
  uploadSessions: [],
  uploadSessionsCount: 0,
  uploadSessionsQuery: { ...queryFilter },
  uploadSessionsLoading: false,
  uploadSessionsLoadingMore: false,
  uploadSessionsError: null,
  uploadingImages: [],
  uploadFolder: 'ImageAnalysis',
  images: [],
  imagesLoading: false,
  imagesLoadingMore: false,
  imagesTotalCount: 0,
  imagesQuery: { ...projectImagesQuery },
  settings: [],
  settingsLoading: false,
  selectedImage: null,
  selectedImageLoading: false,
  selectedImageTags: [],
  selectedImageTagsLoading: false,
  selectedImageAudit: {
    items: [],
    totalCount: 0,
  },
  selectedImageAuditLoading: false,
  selectedImagesIds: [],
  bulkUpdateLoading: false,
  imagesLink: null,
  imageLinkIds: [],
  zipFiles: [],
  zipFilesTotalCount: 0,
  zipFilesQuery: { ...queryFilter },
  zipFilesLoading: false,
  zipFileLoading: false,
  creatingZipLoading: false,
  imageVisibility: [],
  followersLoading: false,
  comments: [],
  commentsTotalCount: 0,
  commentsLoading: false,
  lastCheckedId: null,
  lastProjectId: null,
};
